import "./landing.scss";

import Helmet from "react-helmet";

const Landing = ({ menuOpen, setMenuOpen }) => {
  const seo = (
    <Helmet>
      <meta
        name="description"
        content="zVAPEz is a vape Shop in Fairmont, WV that carries many products associated with vaping"
      />
      <meta
        name="keywords"
        content="Vape, Mods, Juice, Vape Juice, Mod Accessories, delta 8, CBD, CBD gummies, delta 8 gummies, pipes, disposable, wraps"
      />
      <title>
        zVAPEz | Vape Juice | Mod Accessories | Delta 8 | CBD | & More
      </title>
    </Helmet>
  );
  return (
    <div className="landing">
      {seo}
      {/* <img className="bg" src={bg} alt="" /> */}
      <div className="wrapper">
        <div className="backg">
          <div className="heading">
            <h1 className="first">z</h1>
            <h1>VAPE</h1>
            <h1 className="last">z</h1>
          </div>
          <div className="headline">
            <h2>Fairmont's premier vape shop</h2>
          </div>
          <div className="hours">
            {" "}
            <h2>Store Hours</h2>
            <ul>
              <li>Monday - Thursday:</li>
              <li>11 AM - 8 PM</li>
              <li>Friday - Saturday:</li>
              <li>11 AM - 9 PM</li>
              <li>Sunday:</li>
              <li>12 PM - 6 PM</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
