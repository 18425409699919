import { useState } from "react";
import Contact from "../contact/Contact";
import "../navbar/navbar.scss";

const Navbar = ({ menuOpen, setMenuOpen }) => {
  const [nav, setNav] = useState(false);

  const navBackground = () => {
    if (window.scrollY > 100) {
      setNav(true);
    } else {
      setNav(false);
    }
  };

  window.addEventListener("scroll", navBackground);

  return (
    <div
      className={"navbar " + (menuOpen && "open")}
      id={nav ? "activenav" : "inactivenav"}
    >
      <Contact nav={nav} setNav={setNav} />
      <div className="wrapper">
        <div className="left">
          <div className="logo">
            <a href="/">
              <h2 className="first">z</h2>
              <h2>VAPE</h2>
              <h2 className="first">z</h2>
            </a>
          </div>
        </div>
        <div className="right">
          <div className="links">
            <ul>
              <a href="about">
                <li>About Us</li>
              </a>

              <a href="gallery">
                <li>Gallery</li>
              </a>
              <a href="products">
                <li>Products</li>
              </a>
              <a href="reviews">
                <li>Reviews</li>
              </a>
            </ul>
          </div>
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
