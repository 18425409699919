import "./app.scss";
import About from "./components/about/About";
import Products from "./components/products/Products";
import Gallery from "./components/gallery/Gallery";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Main from "./components/main/Main";
import Reviews from "./components/reviews/Reviews";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Routes>
      <Route
        path="/"
        element={<Layout menuOpen={menuOpen} setMenuOpen={setMenuOpen} />}
      >
        <Route index element={<Main />} />
        <Route path="about" element={<About />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="products" element={<Products />} />
        <Route path="reviews" element={<Reviews />} />
      </Route>
    </Routes>
  );
}

export default App;
