import React from "react";
import "./reviews.scss";
import StarRateIcon from "@mui/icons-material/StarRate";
import Helmet from "react-helmet";

const Reviews = () => {
  const seo = (
    <Helmet>
      <meta
        name="description"
        content="zVAPEz is a vape Shop in Fairmont, WV that carries many products associated with vaping"
      />
      <meta
        name="keywords"
        content="Vape, Mods, Juice, Vape Juice, Mod Accessories, delta 8, CBD, CBD gummies, delta 8 gummies, pipes, disposable, wraps"
      />
      <title>zVAPEz | Reviews</title>
    </Helmet>
  );
  const data = [
    {
      id: "1",
      user: "BEST BUY ONLINE",
      icon: "B",
      comments:
        "Amazing New Vape shop. The prices are better Than anyone in the Area. We drive an hour to get our vapez supply, Thanks MZ for hooking us up!",
    },
    {
      id: "2",
      user: "Boomer Rennial",
      icon: "B",
      comments:
        "Picked up some cbd here, good price and the owner is a cool dude. Thank you will be comin back for more for sure",
    },
    {
      id: "3",
      user: "Jason Prichard",
      icon: "J",
      comments:
        "Honestly surpised for a new vape shop how good it is and how cool the owner is, guys got decent products for good prices and is very knowledgeable about his stuff. 🙏🏻🙏🏻🙏🏻",
    },
    {
      id: "4",
      user: "eSBoi i (eSBoi)",
      icon: "E",
      comments: "Good price good vibes",
    },
  ];
  return (
    <div className="reviews">
      {seo}
      <h1>Reviews</h1>
      <div className="container">
        {data.map((d) => (
          <div className="card" key={d.id}>
            <div className="top">
              <h2>{d.icon}</h2>
              <StarRateIcon />
              <StarRateIcon />
              <StarRateIcon />
              <StarRateIcon />
              <StarRateIcon />
            </div>
            <div className="center">{d.comments}</div>
            <div className="bottom">
              <h3>- {d.user}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
