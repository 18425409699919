import React, { useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import "./contact.scss";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";

const Contact = ({ menuOpen, setMenuOpen }) => {
  const [nav, setNav] = useState(false);

  const navBackground = () => {
    if (window.scrollY > 100) {
      setNav(true);
    } else {
      setNav(false);
    }
  };

  window.addEventListener("scroll", navBackground);
  return (
    <div className="contactbar" id={nav ? "activebar" : "inactivebar"}>
      <div className="contactwrapper">
        <div className="left">
          <div className="icons">
            <a
              href="https://www.facebook.com/ZvapeZ/"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon className="fbicon" />
            </a>
            <a href="Tel:304-534-8000">
              <LocalPhoneIcon className="phoneicon" />
            </a>
            <a href="mailto:contact@zvapez.com">
              <MailIcon className="phoneicon" />
            </a>
          </div>
        </div>
        <div className="right">
          <a
            href="https://goo.gl/maps/Q3GzdcQ18KHdKFMc6"
            target="_blank"
            rel="noreferrer"
          >
            <LocationOnIcon className="locicon" />
            <span className="address">310 Merchant St Fairmont, WV 26554</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
