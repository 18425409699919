import React from "react";
import "../app.scss";
import Navbar from "./navbar/Navbar";
import Menu from "./menu/Menu";
import { Outlet } from "react-router-dom";

const Layout = ({ menuOpen, setMenuOpen }) => {
  return (
    <div className="app">
      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className="pages">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
