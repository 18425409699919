import React from "react";
import Landing from "../landing/Landing";
import "./main.scss";

const Main = () => {
  return (
    <div className="main">
      <Landing />
    </div>
  );
};

export default Main;
