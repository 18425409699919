import React, { useState } from "react";
import "./gallery.scss";
import CloseIcon from "@mui/icons-material/Close";
import Helmet from "react-helmet";

const Gallery = () => {
  const seo = (
    <Helmet>
      <meta
        name="description"
        content="zVAPEz is a vape Shop in Fairmont, WV that carries many products associated with vaping"
      />
      <meta
        name="keywords"
        content="Vape, Mods, Juice, Vape Juice, Mod Accessories, delta 8, CBD, CBD gummies, delta 8 gummies, pipes, disposable, wraps"
      />
      <title>zVAPEz | Gallery</title>
    </Helmet>
  );
  const data = [
    {
      id: "1",
      img: "assets/gallery/1.jpeg",
    },
    {
      id: "2",
      img: "assets/gallery/2.jpeg",
    },
    {
      id: "3",
      img: "assets/gallery/3.jpeg",
    },
    {
      id: "4",
      img: "assets/gallery/4.jpeg",
    },

    {
      id: "5",
      img: "assets/gallery/5.jpeg",
    },
    {
      id: "6",
      img: "assets/gallery/6.jpeg",
    },
    {
      id: "7",
      img: "assets/gallery/7.jpeg",
    },
    {
      id: "8",
      img: "assets/gallery/8.jpeg",
    },
    {
      id: "9",
      img: "assets/gallery/9.jpeg",
    },
    {
      id: "10",
      img: "assets/gallery/10.jpeg",
    },
    {
      id: "11",
      img: "assets/gallery/11.jpeg",
    },
    {
      id: "12",
      img: "assets/gallery/12.jpeg",
    },
    {
      id: "13",
      img: "assets/gallery/13.jpeg",
    },
    {
      id: "14",
      img: "assets/gallery/14.jpeg",
    },
    {
      id: "15",
      img: "assets/gallery/15.jpeg",
    },
    {
      id: "16",
      img: "assets/gallery/16.jpeg",
    },
    {
      id: "17",
      img: "assets/gallery/17.jpeg",
    },
    {
      id: "18",
      img: "assets/gallery/18.jpeg",
    },
    {
      id: "19",
      img: "assets/gallery/19.jpeg",
    },
    {
      id: "20",
      img: "assets/gallery/20.jpeg",
    },
    {
      id: "21",
      img: "assets/gallery/21.jpeg",
    },
    {
      id: "22",
      img: "assets/gallery/22.jpeg",
    },
  ];
  const [model, setModel] = useState(false);
  const [tempimg, setTempImg] = useState("");
  const getImg = (img) => {
    setTempImg(img);
    setModel(true);
  };
  return (
    <>
      {seo}
      <div className={model ? "model open" : "model"}>
        <img src={tempimg} alt="" />
        <CloseIcon onClick={() => setModel(false)} />
      </div>
      <div className="gallery">
        {data.map((item, index) => {
          return (
            <div className="pics" key={index} onClick={() => getImg(item.img)}>
              <img src={item.img} alt="" style={{ width: "100%" }} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Gallery;
