import React, { useEffect, useState } from "react";
import "./about.scss";
import Helmet from "react-helmet";

const About = () => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (index < 4) setIndex(index + 1);
      else if (index === 4) setIndex(0);
    }, 5000);
    return () => clearInterval(intervalId);
  });

  const seo = (
    <Helmet>
      <meta
        name="description"
        content="zVAPEz is a vape Shop in Fairmont, WV that carries many products associated with vaping"
      />
      <meta
        name="keywords"
        content="Vape, Mods, Juice, Vape Juice, Mod Accessories, delta 8, CBD, CBD gummies, delta 8 gummies, pipes, disposable, wraps"
      />
      <title>zVAPEz | About Us</title>
    </Helmet>
  );

  return (
    <div className="about">
      {seo}
      <div className="left">
        <div className="imgContainer">
          <img src={`assets/about/${index}.jpeg`} alt="" />
        </div>
      </div>
      <div className="right">
        <div className="desc">
          <h1>zVAPEz is a contemporary vape shop located in Fairmont, WV. </h1>
          <h2>
            A one stop shop for all your Vaping needs and much more. E-Juice,
            E-liquid, Delta 8, CBD, RTA’s, RDA’s, disposables, coils, tanks,
            smoke pipes etc.
          </h2>
          <h3>
            Store owner MZ personally selects the latest and finest products
            from manufacturers around the country to offer his clients the
            ultimate vaping experience in the Fairmont area. MZ is passionate
            about vape culture and wants to bring the best it has to offer to
            everyone in Fairmont and beyond.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default About;
