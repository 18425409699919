import "./menu.scss";

import React from "react";

const Menu = ({ menuOpen, setMenuOpen }) => {
  return (
    <div className="screen" onClick={() => setMenuOpen(false)}>
      <div className={"menu " + (menuOpen && "open")}>
        <ul>
          <li onClick={() => setMenuOpen(false)}>
            <a href="/">Home</a>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <a href="about">About Us</a>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <a href="gallery">Gallery</a>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <a href="products">Products</a>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <a href="reviews">Reviews</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
