import React from "react";
import "./products.scss";
import Helmet from "react-helmet";

const Products = () => {
  const seo = (
    <Helmet>
      <meta
        name="description"
        content="zVAPEz is a vape Shop in Fairmont, WV that carries many products associated with vaping"
      />
      <meta
        name="keywords"
        content="Vape, Mods, Juice, Vape Juice, Mod Accessories, delta 8, CBD, CBD gummies, delta 8 gummies, pipes, disposable, wraps"
      />
      <title>zVAPEz | Products</title>
    </Helmet>
  );
  return (
    <div className="products">
      {seo}
      <div className="left">
        <ul>
          <li>Mod accessories</li>
          <li>Tanks</li>
          <li>Coils</li>
          <li>Rebuildable Dripper Atomizers (RDA)</li>
          <li>Rebuildable Tank Atomizers (RTA)</li>
        </ul>
      </div>
      <div className="middle">
        <ul>
          <li>Vape juice</li>
          <li>Salt Nics</li>
          <li>MODS</li>
          <li>PODS</li>
          <li>Disposables</li>
          <li>CBD</li>
          <li>Kratom</li>
          <li>Delta 8</li>
          <li>PIPES</li>
          <li>Torches</li>
          <li>Wraps</li>
        </ul>
      </div>
      <div className="right">
        <ul>
          <li>Pipe accessories</li>
          <li>Bangers</li>
          <li>Bubbles</li>
          <li>Nails</li>
        </ul>
      </div>
    </div>
  );
};

export default Products;
